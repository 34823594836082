<template>
  <v-app>
    <v-card class="hidden-md-and-down" style="height: 100%; width: 100%" v-if="!$vuetify.breakpoint.mdAndDown">
      <v-app-bar flat color="white" dense app>
        <v-row>
          <v-col
            cols="2"
            offset="2"
            :class="{
              'text-right': true,
              'red--text': this.renewal_notification && (this.package.bills - bill_usage_count < 100 && this.package.bills - bill_usage_count >= 0),
              'white--text': !(this.renewal_notification && (this.package.bills - bill_usage_count < 100 && this.package.bills - bill_usage_count > 0 )) ,
              }"
          >
            บิลเหลืออีก
            {{
              this.package.is_unlimit
                ? 'ไม่จำกัด'
                : `${this.package.bills - this.bill_usage_count} บิล`
            }}
          </v-col>
          <v-col
            cols="2"
            offset="1"
            :class="{
              'text-right': true,
              'red--text': this.renewal_notification && this.checkDate() < 8,
              'white--text': !(this.renewal_notification && this.checkDate() < 8),
              }"
          >
            แพ็คเกจหมดอายุ {{ formatDate(this.package.end_date) }}
          </v-col>

          <v-col
            cols="2"
            offset="1"
            class="text-right"
            >สาขาปัจจุบัน</v-col
          >
          <v-col cols="2" class="pt-1">
            <v-select
              :value="$store.state.selected_branch._id"
              :items="$store.state.list.branchs"
              item-value="_id"
              item-text="branch_name"
              hide-details
              dense
              @input="(_id) => $store.commit('setSelectedBranch', _id)"
            ></v-select>
          </v-col>
        </v-row>
      </v-app-bar>
      
      <v-navigation-drawer
        permanent
        expand-on-hover
        class="menu-icon"
        v-if="this.menuShow == true"
      >
        <div>
          <v-list dense link>
            <div v-for="menu in menu_list" :key="menu.name" link>
              <v-menu v-if="menu.show || menu.show === 2">
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-if="menu.url"
                    class="menu-icon-list"
                    :class="{ active: menu.name === menu_selected.name }"
                    @click="
                      (menu_selected = menu),
                        goUrl(menu.url),
                        (menu_selected = '')
                    "
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-list-item link>
                      <v-list-item-icon>
                        <v-icon center color="white">{{ menu.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title class="white--text">
                        <span class="title-menu">{{
                          menu.text
                        }}</span></v-list-item-title
                      >
                    </v-list-item>
                  </div>
                  <!-- <div
                    v-else
                    class="menu-icon-list"
                    :class="{ active: menu.name == menu_selected.name }"
                    @click="(menu_selected = menu), (drawer = true)"
                    v-bind="attrs"
                    v-on="on"
                  > -->
                  <v-list-group
                    v-else
                    class="white--text menu-icon-list"
                    color="white"
                  >
                    <v-icon slot="appendIcon" color="white"
                      >mdi-menu-down</v-icon
                    >
                    <template v-slot:activator>
                      <v-list-item-icon>
                        <v-icon center color="white">{{ menu.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title class="white--text">
                        <span class="title-menu">{{
                          menu.text
                        }}</span></v-list-item-title
                      >
                    </template>
                    <v-list-item-subtitle class="sub-menu pl-15 ml-3 mt-2">
                      <div
                        class="mb-5"
                        v-for="item in menu.sub_menu"
                        :key="item.name"
                        link
                      >
                        <router-link
                          class="
                            sub-menu-list
                            grey--text
                            text--lighten-4
                            title-menu
                          "
                          :to="item.url"
                          v-on:click.native="
                            (submenu_selected = item.name), (drawer = false)
                          "
                          v-if="item.show || item.show == 2"
                          >{{ item.name }}</router-link
                        >
                      </div>
                    </v-list-item-subtitle>
                  </v-list-group>

                  <!-- </div> -->
                </template>
              </v-menu>
            </div>

            <v-divider class="my-5"></v-divider>
            <div link>
              <div v-for="menu2 in menu_list2" :key="menu2">
                <div v-if="menu2.show || menu2.show === 2">
                  <div
                    v-if="menu2.url"
                    class="menu-icon-list"
                    :class="{ active: menu2.name === menu_selected.name }"
                    @click="(menu_selected = menu2), goUrl(menu2.url)"
                  >
                    <v-list-item link>
                      <v-list-item-icon>
                        <v-icon center color="white">{{ menu2.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title class="white--text">
                        <span class="title-menu">{{
                          menu2.text
                        }}</span></v-list-item-title
                      >
                    </v-list-item>
                  </div>

                  <!-- <div
                    v-else
                    class="menu-icon-list"
                    :class="{ active: menu2.name == menu_selected.name }"
                    @click="menu_selected = menu2"
                  > -->
                  <v-list-group v-else class="white--text" color="white">
                    <v-icon slot="appendIcon" color="white"
                      >mdi-menu-down</v-icon
                    >
                    <template v-slot:activator>
                      <v-list-item-icon>
                        <v-icon center color="white">{{ menu2.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title class="white--text">
                        <span class="title-menu">{{
                          menu2.text
                        }}</span></v-list-item-title
                      >
                    </template>
                    <v-list-item-subtitle
                      class="sub-menu pl-15 ml-3 mt-2 mx-auto"
                    >
                      <div
                        class="mb-5"
                        v-for="item in menu2.sub_menu"
                        :key="item"
                        link
                      >
                        <router-link
                          class="
                            sub-menu-list
                            grey--text
                            text--lighten-4
                            title-menu
                          "
                          :to="item.url"
                          v-on:click.native="submenu_selected = item.name"
                          >{{ item.name }}</router-link
                        >
                      </div>
                    </v-list-item-subtitle>
                  </v-list-group>
                  <!-- </div> -->
                </div>
              </div>

              <v-list-group
                class="white--text"
                color="white"
                style="margin-left: -23px"
              >
                <v-icon slot="appendIcon" color="white">mdi-menu-down</v-icon>
                <template v-slot:activator>
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-img :src="'/cat.png'"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-title class="white--text">
                      <span class="title-menu">{{
                        $store.state.profile.name
                      }}</span></v-list-item-title
                    ></v-list-item
                  >
                </template>
                <v-list-item link @click="logout">
                  <v-list-item-title
                    class="ml-15 pl-1 grey--text text--lighten-4"
                    >ออกจากระบบ</v-list-item-title
                  >
                  <v-list-item-icon>
                    <v-icon color="white">mdi-logout</v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item-subtitle class="sub-menu mb-2 ml-13 mb-2">
                  <v-row dense>
                    <v-col cols="12" class="mt-6 grey--text text--lighten-3"
                      >มีปัญหาใช้งานโปรแกรม</v-col
                    >
                    <!-- <v-col cols="12" class="grey--text text--lighten-3"
                      >โทร: 098-686-2277</v-col
                    > -->
                    <v-col cols="12" class="grey--text text--lighten-3"
                      >Line: @P9944 (มีตัว @ จ้า)
                    </v-col>
                  </v-row>
                </v-list-item-subtitle>
              </v-list-group>
            </div>
          </v-list>
        </div>
      </v-navigation-drawer>
      <v-main class="ml-15">
        <v-container fluid>
          <router-view v-if="!$store.getters.isLoading" :key="componentKey" />
        </v-container>
      </v-main>

      <LoadingPage />
    </v-card>

    <div class="hidden-lg-and-up" style="height: 100%; width: 100%" v-if="$vuetify.breakpoint.mdAndDown">
      <v-app-bar
        color="#2196f3"
        dark
        flat
        style="z-index: 8; position: fixed; top: 0%"
      >
        <v-app-bar-nav-icon
          @click="drawer = true"
          v-if="this.menuShow == true"
        ></v-app-bar-nav-icon>

        <v-row>
          <v-col cols="3">
            <v-toolbar-title>เพิ่มตัง</v-toolbar-title>
          </v-col>

          <v-spacer></v-spacer>
          <v-col class="sub-menu mb-2 white--text">
            <v-row dense>
              <v-col cols="2" class="pt-2">สาขา</v-col>
              <v-col cols="10" class="pl-5">
                <v-select
                  style="max-width: 300px"
                  dark
                  :value="$store.state.selected_branch._id"
                  :items="$store.state.list.branchs"
                  item-value="_id"
                  item-text="branch_name"
                  hide-details
                  dense
                  @input="(_id) => $store.commit('setSelectedBranch', _id)"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-app-bar>

      <v-row class="mt-16" style="z-index: 1; height: 0px">
        <v-col
          v-if="
            this.package.bills - bill_usage_count < 200 || this.checkDate() < 8
          "
          cols="6"
          class="text-center red--text"
        >
          บิลเหลืออีก
          {{
            this.package.is_unlimit
              ? 'ไม่จำกัด'
              : `${this.package.bills - this.bill_usage_count} บิล`
          }}
        </v-col>
        <v-col
          v-if="
            this.package.bills - bill_usage_count < 200 || this.checkDate() < 8
          "
          cols="6"
          class="text-center red--text"
        >
          ครบกำหนด {{ formatDate(this.package.end_date) }}
        </v-col>
      </v-row>

      <v-navigation-drawer
        absolute
        class="menu-icon mt-14"
        v-model="drawer"
        v-if="this.menuShow == true"
      >
        <v-list dense>
          <div v-for="menu in menu_list" :key="menu.name" link>
            <v-menu v-if="menu.show || menu.show === 2">
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-if="menu.url"
                  class="menu-icon-list"
                  :class="{ active: menu.name === menu_selected.name }"
                  @click="
                    (menu_selected = menu),
                      goUrl(menu.url),
                      (menu_selected = '')
                  "
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-list-item link>
                    <v-list-item-icon>
                      <v-icon center color="white">{{ menu.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="white--text"
                      ><span class="title-menu">{{
                        menu.text
                      }}</span></v-list-item-title
                    >
                  </v-list-item>
                </div>
                <!-- <div
                    v-else
                    class="menu-icon-list"
                    :class="{ active: menu.name == menu_selected.name }"
                    @click="(menu_selected = menu), (drawer = true)"
                    v-bind="attrs"
                    v-on="on"
                  > -->
                <v-list-group
                  v-else
                  class="white--text menu-icon-list"
                  color="white"
                >
                  <v-icon slot="appendIcon" color="white">mdi-menu-down</v-icon>
                  <template v-slot:activator>
                    <v-list-item-icon>
                      <v-icon center color="white">{{ menu.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="white--text"
                      ><span class="title-menu">{{
                        menu.text
                      }}</span></v-list-item-title
                    >
                  </template>
                  <v-list-item-subtitle class="sub-menu pl-15 ml-3 mt-2">
                    <div
                      class="mb-5"
                      v-for="item in menu.sub_menu"
                      :key="item.name"
                      link
                    >
                      <router-link
                        class="sub-menu-list grey--text text--lighten-4"
                        :to="item.url"
                        v-on:click.native="
                          (submenu_selected = item.name), (drawer = false)
                        "
                        v-if="item.show || item.show == 2"
                        >{{ item.name }}</router-link
                      >
                    </div>
                  </v-list-item-subtitle>
                </v-list-group>

                <!-- </div> -->
              </template>
            </v-menu>
          </div>

          <v-divider></v-divider>

          <div link>
            <div v-for="menu2 in menu_list2" :key="menu2">
              <div v-if="menu2.show || menu2.show === 2">
                <div
                  v-if="menu2.url"
                  class="menu-icon-list"
                  :class="{ active: menu2.name === menu_selected.name }"
                  @click="(menu_selected = menu2), goUrl(menu2.url)"
                >
                  <v-list-item link>
                    <v-list-item-icon>
                      <v-icon center color="white">{{ menu2.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="white--text"
                      ><span class="title-menu">
                        {{ menu2.text }}></span
                      ></v-list-item-title
                    >
                  </v-list-item>
                </div>

                <!-- <div
                    v-else
                    class="menu-icon-list"
                    :class="{ active: menu2.name == menu_selected.name }"
                    @click="menu_selected = menu2"
                  > -->
                <v-list-group v-else class="white--text" color="white">
                  <v-icon slot="appendIcon" color="white">mdi-menu-down</v-icon>
                  <template v-slot:activator>
                    <v-list-item-icon>
                      <v-icon center color="white">{{ menu2.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="white--text"
                      ><span class="title-menu">{{
                        menu2.text
                      }}</span></v-list-item-title
                    >
                  </template>
                  <v-list-item-subtitle
                    class="sub-menu pl-15 ml-3 mt-2 mx-auto"
                  >
                    <div
                      class="mb-5"
                      v-for="item in menu2.sub_menu"
                      :key="item"
                      link
                    >
                      <router-link
                        class="sub-menu-list grey--text text--lighten-4"
                        :to="item.url"
                        v-on:click.native="submenu_selected = item.name"
                        >{{ item.name }}</router-link
                      >
                    </div>
                  </v-list-item-subtitle>
                </v-list-group>
                <!-- </div> -->
              </div>
            </div>

            <v-list-group
              class="white--text"
              color="white"
              style="margin-left: -20px"
              ><v-icon slot="appendIcon" color="white">mdi-menu-down</v-icon>
              <template v-slot:activator>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-img :src="'/cat.png'"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-title class="white--text"
                    ><span class="title-menu">
                      {{ $store.state.profile.name }}</span
                    ></v-list-item-title
                  ></v-list-item
                >
              </template>
              <v-list-item link @click="logout">
                <v-list-item-title class="ml-15 pl-1 grey--text text--lighten-4"
                  >ออกจากระบบ</v-list-item-title
                >
                <v-list-item-icon>
                  <v-icon color="white">mdi-logout</v-icon>
                </v-list-item-icon>
              </v-list-item>

              <v-list-item class="sub-menu mb-2 ml-13 mb-2">
                <v-row dense>
                  <v-col cols="12" class="mt-6 grey--text text--lighten-3"
                    >มีปัญหาใช้งานโปรแกรม</v-col
                  >
                  <!-- <v-col cols="2" class="grey--text text--lighten-3"
                    >โทร:</v-col
                  > -->
                  <!-- <v-col cols="10" class="grey--text text--lighten-3"
                    >098-686-2277</v-col
                  > -->
                  <v-col cols="3" class="grey--text text--lighten-3"
                    >Line:
                  </v-col>
                  <v-col cols="9" class="grey--text text--lighten-3">
                    @P9944 (มีตัว @ จ้า)</v-col
                  >
                </v-row>
              </v-list-item>
            </v-list-group>
          </div>
        </v-list>
      </v-navigation-drawer>

      <v-main>
        <v-container fluid>
          <router-view v-if="!$store.getters.isLoading" :key="componentKey" />
        </v-container>
      </v-main>

      <LoadingPage />
    </div>

  </v-app>
</template>

<script>
import Vue from 'vue';
import LoadingPage from '@/components/LoadingPage.vue';
import { mapState } from 'vuex';
import * as moment from 'moment';

// const asyncLocalStorage = {
//   setItem: function (key, value) {
//     return Promise.resolve().then(function () {
//       localStorage.setItem(key, value);
//     });
//   },
//   getItem: function (key) {
//     return Promise.resolve().then(function () {
//       return localStorage.getItem(key);
//     });
//   },
// };
export default {
  components: {
    LoadingPage,
  },
  data: () => ({
    // selected_branch: {
    //   _id: 0,
    //   branch_name: "",
    //   phone_number: "",
    // },
    expandOnHover: true,
    setup_wizard: null,
    filter: {
      item_per_page: 20,
      page: 1,
      start_with: '',
    },
    menuShow: true,
    alertPackage: 0,
    today: moment().format('YYYY-MM-DD'),
    componentKey: 0,
    list: {
      branchs: [],
    },
    menu_icon_width: 50,
    name: 'Dashboard',
    drawer: false,

    menu_selected: '',
    submenu_selected: '',
    package: {},
    bill_usage_count: 0,
    renewal_notification: true,
    menu_list: [
      { name: 'chart', icon: 'mdi-finance', text: 'ภาพรวม', url: '/pages/home', show: true },
      { name: 'calculator', icon: 'mdi-calculator', text: 'คำนวณ', url: '/pages/document/quotation/detail', show: true },
      { name: 'car', icon: 'mdi-car-side', text: 'ข้อมูลรถ', url: '/pages/car/car', show: true },
    ],

    menu_list2: [
      {
        name: 'package',
        icon: ' mdi-package-variant',
        text: 'แพ็คเกจ',
        sub_menu: [
          { name: 'แพ็คเกจของคุณ', url: '/pages/package/your' },
          // // { name: 'ใบแจ้งหนี้', url: '/pages/package/invoice' },
          { name: 'ประวัติการชำระเงิน', url: '/pages/package/payment' },
          // { name: 'บัตรเครดิต', url: '/pages/package/credit ' },
        ],
      },
      {
        name: 'setting',
        icon: 'mdi-cog',
        text: 'ตั้งค่า',
        sub_menu: [
          { name: 'ตั้งค่าสาขา', url: '/pages/setting/business-branch' },
          { name: 'ตั้งค่าธุรกิจ', url: '/pages/setting/business' },
          { name: 'ตั้งค่าเอกสาร', url: '/pages/setting/document' },
          { name: 'ตั้งค่า SMS', url: '/pages/setting/sms' },
          { name: 'ตั้งค่า จดหมาย', url: '/pages/setting/mail' },
          { name: 'กลุ่มผู้ใช้งาน', url: '/pages/setting/user-group' },
          { name: 'ผู้ใช้งาน', url: '/pages/setting/user' },
          {
            name: 'ค่าคอมพ.ร.บ. ประกันภัย',
            url: '/pages/setting/insurance',
          },
          // { name: 'พนักงาน', url: '/pages/setting/a' },
        ],
      },
    ],
    dataFetched: false,
    links: ['Home', 'Contacts', 'Settings', 'Menu 1', 'Menu 2', 'Menu 3'],
    mini: true,
    permission: {},
  }),

  mounted() {
    this.menu_selected = this.menu_list[0];
  },

  async created() {
    //this.getBranch();
    if(this.$store.state.selected_branch._id == ''){
      await this.$store.dispatch('getBranchs');
      await this.$store.dispatch('getMyProfile');
      await this.$store.dispatch('getGroupPermissions');
      // console.error('No selected_branch._id on Dashboard')
    }
    //this.$store.dispatch('getProvinces');
    await this.getUserGroup();
    this.getRemainingPackage();
    //this.getCheck();
  },

  methods: {
    async getCheck() {
      let body = {
        token: this.$jwt.sign(
          { branch_id: this.selected_branch._id },
          this.$privateKey,
          {
            noTimestamp: true,
          }
        ),
      };
      await this.$axios
        .post(`${this.$baseUrl}/wizard/check`, body)
        .then((res) => {
          this.setup_wizard = res.result;
          if (this.setup_wizard === 0) {
            this.menuShow = false;
            this.$router.push(
              `/pages/setup/business-branch/${this.selected_branch._id}`
            );
          } else if (this.setup_wizard === 1) {
            this.$router.push(
              `/pages/setup/business-branch/${this.selected_branch._id}`
            );
          } else if (this.setup_wizard === 2) {
            this.$router.push(
              `/pages/setup/insurance/${this.selected_branch._id}`
            );
          } else if (this.setup_wizard === 3) {
            this.$router.push(
              `/pages/setup/business/${this.selected_branch._id}`
            );
          } else if (this.setup_wizard === 4) {
            this.$router.push(
              `/pages/setup/price-product/${this.selected_branch._id}`
            );
          } else if (this.setup_wizard === 5) {
            this.$router.push(
              `/pages/setup/price-product/${this.selected_branch._id}`
            );
          } else {
            this.menuShow = true;
          }
        })
        .catch((err) => {
          console.error('err', err);
        });
    },

    async getRemainingPackage() {
      let body = {
        token: this.$jwt.sign({}, this.$privateKey, { noTimestamp: true }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/package/get_package_remaining`, body)
        .then((res) => {
          this.package = res.result.package;
          this.bill_usage_count = res.result.bill_usage_count;
          this.renewal_notification = res.result.renewal_notification;
          this.checkDate();
        })
        .catch((err) => {
          console.error('err', err);
          this.$alertServerError({ title: err.error_message });
        });
    },
    formatDate(date) {
      if (!date) return '';

      let year = parseInt(moment(date).format('YYYY'));
      let m_d = moment(date).format('DD/MM');
      return `${m_d}/${year + 543}`;
    },

    goUrl(url) {
      this.$router.push(url).catch(() => {});
    },
    logout: async function () {
      this.$router.push({ name: 'login' });
    },
    async getUserGroup() {
      try {
         if (Object.keys(this.$store.state.group_permissions).length === 0) {
            await this.$store.dispatch('getGroupPermissions');
            console.log("No getGroupPermissions")
        }

        this.permission = this.$store.state.group_permissions;
          let showExpenses =
            this.permission.expenses == 1 ||
            this.permission.expenses == 2 ||
            this.permission.expense_car_tax == 1 ||
            this.permission.expense_car_tax == 2 ||
            this.permission.expense_insurance == 1 ||
            this.permission.expense_insurance == 2 ||
            this.permission.expense_category == 1 ||
            this.permission.expense_category == 2;
          let report =
            this.permission.report_quotation == 1 ||
            this.permission.report_quotation == 2 ||
            this.permission.report_unpaid == 1 ||
            this.permission.report_unpaid == 2 ||
            this.permission.report_total_sales == 1 ||
            this.permission.report_total_sales == 2 ||
            this.permission.report_product_sales == 1 ||
            this.permission.report_product_sales == 2 ||
            this.permission.report_staff_sales == 1 ||
            this.permission.report_staff_sales == 2 ||
            this.permission.report_profit_loss == 1 ||
            this.permission.report_profit_loss == 2 ||
            this.permission.report_outgoing_cost == 1 ||
            this.permission.report_outgoing_cost == 2 ||
            this.permission.report_commission == 1 ||
            this.permission.report_commission == 2 ||
            this.permission.report_insurance == 1 ||
            this.permission.report_insurance == 2 ||
            this.permission.salary == 1 ||
            this.permission.salary == 2 ||
            this.permission.report_invoice == 1 ||
            this.permission.report_invoice == 2 ||
            0;
          (this.menu_list = [
            {
              name: 'chart',
              icon: 'mdi-finance',
              text: 'ภาพรวม',
              url: '/pages/home',
              show: true,
            },
            {
              name: 'calculator',
              icon: 'mdi-calculator',
              text: 'คำนวณ (ออกบิลใหม่)',
              url: '/pages/document/quotation/detail',
              show: true,
            },
            {
              name: 'document',
              icon: 'mdi-currency-usd',
              text: 'เอกสาร',
              show: true,
              sub_menu: [
                {
                  name: 'ใบเสนอราคา',
                  url: '/pages/document/quotation',
                  show: true,
                },
                {
                  name: 'ใบแจ้งหนี้ (ค้างชำระ)',
                  url: '/pages/document/invoice',
                  show: true,
                },
                {
                  name: 'ใบเสร็จรับเงิน',
                  url: '/pages/document/receipt',
                  show: true,
                },
                {
                  name: 'ใบเพิ่มหนี้ (เก็บตังเพิ่ม)',
                  url: '/pages/document/credit',
                  show: true,
                },
                {
                  name: 'ใบลดหนี้ (คืนเงินลูกค้า)',
                  url: '/pages/document/debit',
                  show: true,
                },
                // { name: 'คำนวณ', url: '/pages/document/quotation/detail', show: true },
              ],
            },
            {
              name: 'car',
              icon: 'mdi-car-side',
              text: 'ข้อมูลรถ',
              url: '/pages/car/car',
              show: true,
              /*sub_menu: [
                { name: "ข้อมูลรถ", url: "/pages/car/car", show: true },
                { name: "ข้อมูลรถผิดระเบียบ", url: "/pages/car/bancar", show: true,},
                //{ name: 'สอบถาม', url: '/pages/customer/agent' },
                // { name: 'รถแบน', url: '/pages/customer/a' },
              ],*/
            },
            {
              name: 'customer',
              icon: 'mdi-account-box-multiple',
              text: 'ข้อมูลลูกค้า',
              show: true,
              sub_menu: [
                { name: 'ลูกค้า', url: '/pages/customer/customer', show: true },
                { name: 'กลุ่มลูกค้า', url: '/pages/customer/group', show: true },
                { name: 'ตัวแทน', url: '/pages/customer/agent', show: true },
              ],
            },
            {
              name: 'file',
              icon: 'mdi-cart',
              text: 'สินค้า',
              show: true,
              sub_menu: [
                { name: 'สินค้าทั่วไป', url: '/pages/product/product', show: true },
                {
                  name: 'พรบ / ประกันภัย',
                  url: '/pages/product/insurance',
                  show: true,
                },
                { name: 'ขนส่ง', url: '/pages/product/transport', show: true },
              ],
            },
            {
              name: 'follow',
              icon: 'mdi-marker-check',
              text: 'ติดตามงาน',
              show: this.permission.job_follow_up,
              sub_menu: [
                { name: 'ต่อภาษี', url: '/pages/follow/tax', show: true },
                {
                  name: 'แจ้งเตือนลูกค้าต่ออายุ',
                  url: '/pages/follow/alert-customer',
                  show: true,
                },
                {
                  name: 'พรบ. / ประกันภัย',
                  url: '/pages/follow/insurance',
                  show: true,
                },
                {
                name: 'งานขนส่ง / อื่น ๆ',
                url: '/pages/follow/transport',
                show: true
                },

                // { name: 'แจ้งเตือนภายใน', url: '/pages/follow/alert', show: true }
              ],
            },
            // {   name: 'package', icon: ' mdi-package-variant', text: 'แพ็คเกจ',
            //     sub_menu: [
            //         { name: 'แพ็คเกจของคุณ', url: '/pages/package/your' },
            //         // // { name: 'ใบแจ้งหนี้', url: '/pages/package/invoice' },
            //         { name: 'ประวัติการชำระเงิน', url: '/pages/package/payment' },
            //         // { name: 'บัตรเครดิต', url: '/pages/package/credit ' },
            //     ]
            // },
            {
              name: 'expenses',
              icon: 'mdi-upload-multiple',
              text: 'ค่าใช้จ่าย',
              show: showExpenses,
              sub_menu: [
                {
                  name: 'รายจ่าย',
                  url: '/pages/expenses/expenditure',
                  show: this.permission.expenses,
                },
                // { name: 'หัก ณ ที่จ่าย', url: '/pages/expenses/withholding', show: permission.expense_withholding },
                {
                  name: 'รายจ่ายภาษีรถ',
                  url: '/pages/expenses/cartax',
                  show: this.permission.expense_car_tax,
                },
                {
                  name: 'รายจ่าย พรบ ประกันภัย',
                  url: '/pages/expenses/insurance',
                  show: this.permission.expense_insurance,
                },
                {
                  name: 'รายชื่อผู้จำหน่าย',
                  url: '/pages/expenses/dealer',
                  show: true,
                },
                {
                  name: 'หมวดหมู่รายจ่าย',
                  url: '/pages/expenses/expensegroup',
                  show: this.permission.expense_category,
                },
              ],
            },
            {
              name: 'report',
              icon: 'mdi-file-chart',
              text: 'รายงาน',
              show: report,
              sub_menu: [
                {
                  name: 'ยอดใบเสนอราคา',
                  url: '/pages/report/quotations',
                  show: this.permission.report_quotation,
                },
                {
                  name: 'ยอดค้างชำระ',
                  url: '/pages/report/invoice',
                  show: this.permission.report_invoice,
                },
                {
                  name: 'ยอดขายรวม',
                  url: '/pages/report/totalsales',
                  show: this.permission.report_total_sales,
                },
                {
                  name: 'ยอดขายสินค้า',
                  url: '/pages/report/productsales',
                  show: this.permission.report_product_sales,
                },
                {
                  name: 'ยอดขายพนักงาน',
                  url: '/pages/report/employeesales',
                  show: this.permission.report_staff_sales,
                },
                {
                  name: 'กำไรขาดทุน',
                  url: '/pages/report/profit',
                  show: this.permission.report_profit_loss,
                },
                {
                  name: 'ค่าคอม พ.ร.บ. ประกันภัย',
                  url: '/pages/report/insurance',
                  show: this.permission.report_commission,
                },
                {
                  name: 'การแก้ไขเอกสาร',
                  url: '/pages/report/edit-document',
                  show: true,
                },
                /* {
                  name: "ค่าใช้จ่าย",
                  url: "/pages/report/cost",
                  show: this.permission.report_outgoing_cost,
                },
                {
                  name: "คอมมิสขั่น",
                  url: "/pages/report/commission",
                  show: this.permission.report_commission,
                },
                {
                  name: "เงินเดือน",
                  url: "/pages/report/salary",
                  show: this.permission.salary || 0,
                },*/
              ],
            },

            // { name: 'shared', icon: 'mdi-account-multiple', text: 'ค่าใช้จ่าย',
            //     sub_menu: [
            //         { name: 'รายจ่าย', url: '/' },
            //         { name: 'หัก ณ ที่จ่าย', url: '/' },
            //         { name: 'หมวดหมู่รายจ่าย', url: '/' },
            //     ]
            // },

            // { name: 'starred', icon: 'mdi-star' },
            // { name: 'recent', icon: 'mdi-history' },
            // { name: 'offline', icon: 'mdi-check-circle' },
            // { name: 'uploads', icon: 'mdi-upload' },
            // { name: 'backups', icon: 'mdi-cloud-upload' },
          ]),
            (this.menu_list2 = [
              {
                name: 'package',
                icon: ' mdi-package-variant',
                text: 'แพ็คเกจ',
                show: this.permission.package,
                sub_menu: [
                  { name: 'แพ็คเกจของคุณ', url: '/pages/package/your', show: true },
                  // // { name: 'ใบแจ้งหนี้', url: '/pages/package/invoice' },
                  {
                    name: 'การชำระเงิน',
                    url: '/pages/package/payment',
                    show: true,
                  },
                  // { name: 'บัตรเครดิต', url: '/pages/package/credit ' },
                ],
              },

              {
                name: 'setting',
                icon: 'mdi-cog',
                text: 'ตั้งค่า',
                show: this.permission.setting,
                sub_menu: [
                  {
                    name: 'ตั้งค่าสาขา',
                    url: '/pages/setting/business-branch',
                    show: true,
                  },
                  {
                    name: 'ตั้งค่าธุรกิจ',
                    url: '/pages/setting/business',
                    show: true,
                  },
                  {
                    name: 'ตั้งค่าเอกสาร',
                    url: '/pages/setting/document',
                    show: true,
                  },
                  {
                    name: 'ตั้งค่า SMS',
                    url: '/pages/setting/sms',
                    show: true,
                  },
                  {
                    name: 'ตั้งค่า จดหมาย',
                    url: '/pages/setting/mail',
                    show: true,
                  },
                  {
                    name: 'กลุ่มผู้ใช้งาน',
                    url: '/pages/setting/user-group',
                    show: true,
                  },
                  { name: 'ผู้ใช้งาน', url: '/pages/setting/user', show: true },
                  {
                    name: 'ค่าคอมพ.ร.บ. ประกันภัย',
                    url: '/pages/setting/insurance',
                    show: true,
                  },
                  // { name: 'พนักงาน', url: '/pages/setting/a' },
                ],
              },
            ]);
        } catch (error) {
              console.error('Error in getUserGroup:', error.message);
              // Optionally, you can add more specific error handling here, like showing a notification or redirecting the user.
          }
    },
    forceRerender() {
      this.componentKey += 1;
    },
    checkDate() {
      var date1 = this.formatDate(this.package.end_date);
      var date2 = this.formatDate(this.today);
      date1 = date1.split('/');
      date2 = date2.split('/');
      var sDate = new Date(date1[2], date1[1] - 1, date1[0]);
      var eDate = new Date(date2[2], date2[1] - 1, date2[0]);
      var daysDiff = Math.round((sDate - eDate) / 86400000) + 1;
      var bill_canUse = this.package.bills - this.bill_usage_count;
      if (this.renewal_notification) {
        if (daysDiff <= 0 && this.alertPackage == 0) {
          this.$alertConfirm({
            title: `แพ็คเกจท่านหมดอายุแล้ว `,
            html: '</h3><br> <br> <p>ต่ออายุแพ็คเกจ แอดเลย  <br>   Line id: @P9944 (มี @ ด้วย)</p>',
            confirmButtonText: 'ยืนยัน',
            confirmButtonColor: '#999999',
            icon: 'warning',
            showCancelButton: false,
            customClass: {
              cancelButton: 'app-btn-alert-cancel v-btn outlined ',
            },
          }).then(async (result) => {
            if (result.isConfirmed) {
              this.alertPackage = 1;
              // this.$router.push('/pages/package/your');
            }
          });
        } else if (
          daysDiff < 6 &&
          bill_canUse < 11 &&
          this.package.is_unlimit != true &&
          this.alertPackage == 0
        ) {
          this.$alertConfirm({
            title:
              `แพ็คเกจท่านจะหมดอายุใน ` +
              daysDiff +
              ` วัน` +
              `\n` +
              `บิลของท่านเหลืออีก ` +
              bill_canUse +
              ` บิล`,
            html: '<br><h3>กรุณาปรับแพ็คเกจ เพื่อการใช้งานอย่างต่อเนื่อง</h3><br> <br> <p>ต่ออายุแพ็คเกจ แอดเลย  <br>   Line id: @P9944 (มี @ ด้วย)</p>',
            confirmButtonText: 'ยืนยัน',
            confirmButtonColor: '#999999',
            icon: 'warning',

            showCancelButton: false,
            customClass: {
              cancelButton: 'app-btn-alert-cancel v-btn outlined ',
            },
          }).then(async (result) => {
            if (result.isConfirmed) {
              this.alertPackage = 1;
            }
          });
        } else if (
          bill_canUse < 11 &&
          this.alertPackage == 0 &&
          this.package.is_unlimit != true
        ) {
          this.$alertConfirm({
            title: `บิลของท่านเหลืออีก ` + bill_canUse + ` บิล`,
            html: '<br><h3>กรุณาปรับแพ็คเกจ เพื่อการใช้งานอย่างต่อเนื่อง</h3><br> <br> <p>ต่ออายุแพ็คเกจ แอดเลย  <br>   Line id: @P9944 (มี @ ด้วย)</p>',
            confirmButtonText: 'ยืนยัน',
            confirmButtonColor: '#999999',
            icon: 'warning',

            showCancelButton: false,
            customClass: {
              cancelButton: 'app-btn-alert-cancel v-btn outlined ',
            },
          }).then(async (result) => {
            if (result.isConfirmed) {
              this.alertPackage = 1;
            }
          });
        } else if (daysDiff < 6 && this.alertPackage == 0) {
          this.$alertConfirm({
            title: `แพ็คเกจท่านจะหมดอายุใน ` + daysDiff + ` วัน`,
            html:
              '<h3>หมดวันที่ ' +
              `${this.formatDate(this.package.end_date)}` +
              '</h3><br> <br> <p>ต่ออายุแพ็คเกจ แอดเลย  <br>   Line id: @P9944 (มี @ ด้วย)</p>',
            confirmButtonText: 'ยืนยัน',
            confirmButtonColor: '#999999',
            icon: 'warning',
            showCancelButton: false,
            customClass: {
              cancelButton: 'app-btn-alert-cancel v-btn outlined ',
            },
          }).then(async (result) => {
            if (result.isConfirmed) {
              this.alertPackage = 1;
            }
          });
        }
      }
      return daysDiff;
    },
  },
  computed: {
    // hasNavigation() {
    //   this.getRemainingPackage();
    //   return this.menu_selected && this.menu_selected.sub_menu;
    // },
    ...mapState({
      selected_branch: (state) => {
        return {
          _id: state.selected_branch._id,
          branch_name: state.selected_branch.branch_name,
          phone_number: state.selected_branch.phone_number,
        };
      },
    }),
  },
  watch: {
    selected_branch: function (value) {
      this.getCheck();
      this.forceRerender();
      //this.getRemainingPackage();
    },
  },
};
</script>





<style lang="scss" scoped >
$primary: #2ba9fc;
.menu-icon {
  position: fixed;
  height: 100%;
  background-color: $primary;
  z-index: 7;

  .menu-icon-list {
    // cursor: pointer;
    // padding-left: 2px;
    &:hover {
      // background-color: #66a9e7;
    }
    &.active {
      // border-left: white solid 2px;
      // padding-left: 0px;
    }
  }
}

.sub-menu {
  .sub-menu-list {
    cursor: pointer;
    padding-left: 5px;
    text-decoration: none;
    color: #494949;
    padding-right: 100%;
    &:hover {
      background-color: #66a9e7;
      padding-top: 5px;
      padding-bottom: 5px;
    }
    &.router-link-exact-active {
      border-left: white solid 2px;
      padding-left: 5px;
    }
  }
  font-size: 14px;
}
.cursor-logout {
  cursor: pointer;
  &:hover {
    color: white;
  }
}
.theme--light.v-navigation-drawer {
  background: #2196f3!important;
  color: #ffffff;
}
</style>